import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./Series.module.scss";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import useFetchData from "../../../../hooks/useFetchData";
import ContentCard from "../../../../components/contentCards/contentCard/ContentCard";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import SeriesModal from "../../../../components/modals/seriesModal/SeriesModal";
import NoItemsView from "../../../../components/ui/noItemsView/NoItemsView";
import SortHelper from "../../../../utils/SortHelper";
import SearchSortView from "../../../../components/ui/searchSort/searchSortView/SearchSortView";
import serieManager from "../../../../backend/managers/SerieManager";
import Loader from "../../../../components/ui/loader/Loader";
import { errorActions } from "../../../../store/reducers/Error-slice";
import CustomPaginationFooter from "../../../../components/pagination/CustomPaginationFooter";
import ContentCardSkeletonWithPagination from "../../../../components/skeleton/contents/ContentCardSkeletonWithPagination";

function Series() {
  const SORT_OPTIONS = SortHelper.getContentSortOptions();

  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSeries, setSelectedSeries] = useState();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isSeriesModalOpen, setIsSeriesModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedSortType, setSelectedSortType] = useState(SORT_OPTIONS[0]);
  const [seriresTotalResults, setSeriresTotalResults] = useState(0);
  const [seriresTotal, setSeriresTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(18);
  const [currentPage, setCurrentPage] = useState(0);


  const dispatch = useDispatch();

  const createRowsPerPageOptions = (dataLength) => {
    const options = [];
    const maxRows = Math.max(18, dataLength); 
    for (let i = 18; i < maxRows; i += 18) {
      options.push(i);
    }
    if (!options.includes(dataLength)) {
      options.push(dataLength);
    }
    return options;
  };
  
  const rowsPerPageOptions = createRowsPerPageOptions(seriresTotalResults);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0); 
  };


  const fetchSeries = async () => {
    try {
      const result = await serieManager.getSeriesCategoriesApiPagination(
        selectedCategory.id,
        rowsPerPage,
        currentPage,
        searchInputValue,
        selectedSortType.id
      );
      console.log(result);
      
      if (result?.status === 0) {
        setSeries(result.data.series);
        setCategories(result.data.genres); 
        setSeriresTotal(result.data.total); 
        setSeriresTotalResults(result.data.totalResult); 
      }
    } catch (error) {
      console.error("Fetch VODs error:", error); 
      dispatch(errorActions.setErrorMessage("Error fetching vods"));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!searchInputValue) {
      setIsLoading(true); 
    }
    fetchSeries(); 
  }, [searchInputValue, selectedSortType, selectedCategory, rowsPerPage, currentPage]);


  const openSeriesModalHandler = (s, isEditingValue) => {
    setSelectedSeries(s);
    setIsSeriesModalOpen(true);
    setIsEditing(isEditingValue);
  };

  const handleDisplayAll = () => {
    setSelectedCategory(''); 
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);  
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  const handleSortChange = (sortType) => {
    setSelectedSortType(sortType);
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  const handleSearch = (value) => {
    setSearchInputValue(value);
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  return (
    <>
      <div className={classes.page}>
        {/* <MainHeader /> */}
        {isLoading ? (
          <ContentCardSkeletonWithPagination />
        ) : (
          <MenuCardLayout
            // buttons={menuButtons}
            pageTitle={t("series") + " (" + seriresTotalResults + ")"}
            displayAllNumber={seriresTotal}
            isDisplayAllSelected={selectedCategory === ""}
            onSelectDisplayAll={handleDisplayAll}
          >
            <SearchSortView
              hasSort={true}
              options={SORT_OPTIONS}
              selectedSortType={selectedSortType}
              onSelectSortType={handleSortChange}
              searchInputValue={searchInputValue}
              searchInputPlaceholder={t("searchSerie")}
              onClearSearchIput={() => setSearchInputValue("")}
              onChangeSearchInput={handleSearch}
            />
            {categories.map((category) => {
              return (
                category.id !== "display_all" && (
                  <CategoryItem
                    key={category?.id}
                    id={category?.id}
                    name={
                      category?.id !== "display_all"
                        ? category?.name
                        : t(category?.id)
                    }
                    number={category?.seriesCount}
                    onClick={() => handleCategorySelect(category)}
                    selectedCategoryId={selectedCategory?.id}
                  />
                )
              );
            })}
           
            {series.length > 0 ? (
              <>
                <ul className={classes.list}>
                  {series?.map((s) => (
                    <ContentCard
                      className={classes.itemCard}
                      key={s?.id}
                      content={serieManager.getLocalSeriesById(s?.id)}
                      onEdit={() =>
                        openSeriesModalHandler(
                          serieManager.getLocalSeriesById(s?.id),
                          true
                        )
                      }
                    />
                  ))}
                </ul>
                <div className={classes.paginationFooter}>
                  {
                    seriresTotalResults > 17 && (
                      <CustomPaginationFooter
                        totalItems={seriresTotalResults}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        setPage={handlePageChange}
                        setRowsPerPage={handleRowsPerPageChange}
                        rowsPerPageOptions={rowsPerPageOptions}
                      />
                    )
                  }
                </div>
              </>
            ) : (
              !isLoading && (
                <NoItemsView
                  text={t("noItemsRow")}
                  // onClick={() => openSeriesModalHandler(null, false)}
                />
              )
            )}
          </MenuCardLayout>
        )}
      </div>
      {isSeriesModalOpen && (
        <SeriesModal
          isModalOpen={isSeriesModalOpen}
          onClose={() => setIsSeriesModalOpen(false)}
          isEditing={isEditing}
          series={isEditing && selectedSeries}
          disabled={true}
        />
      )}
    </>
  );
}

export default Series;
