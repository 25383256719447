import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import channelManager from "../../../../backend/managers/ChannelManager";
import ContentCard from "../../../../components/contentCards/contentCard/ContentCard";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import ChannelModal from "../../../../components/modals/channelModal/ChannelModal";
import ConfirmationModal from "../../../../components/modals/confirmationModal/ConfirmationModal";
import LinkContentsModal from "../../../../components/modals/linkContentsModal/LinkContentsModal";
import ThemeModal from "../../../../components/modals/themeModal/ThemeModal";
import CustomPaginationFooter from "../../../../components/pagination/CustomPaginationFooter";
import Toast from "../../../../components/toast/Toast";
import ButtonIcon from "../../../../components/ui/buttonIcon/ButtonIcon";
import Loader from "../../../../components/ui/loader/Loader";
import NoItemsView from "../../../../components/ui/noItemsView/NoItemsView";
import SearchSortView from "../../../../components/ui/searchSort/searchSortView/SearchSortView";
import { errorActions } from "../../../../store/reducers/Error-slice";
import ContentHelper from "../../../../utils/ContentHelper";
import SortHelper from "../../../../utils/SortHelper";
import classes from "./LiveTV.module.scss";
import ContentCardSkeletonWithPagination from "../../../../components/skeleton/contents/ContentCardSkeletonWithPagination";

function LiveTV() {
  const SORT_OPTIONS = [...SortHelper.getContentSortOptions(), { id: "higher_rank" }];

  const [categories, setCategories] = useState([]);
  const [channels, setChannels] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedChannel, setSelectedChannel] = useState();
  const [selectedSortType, setSelectedSortType] = useState(SORT_OPTIONS[2]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isLodingCard, setIsLodingCard] = useState(false)
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isLinkContentsModalOpen, setIsLinkContentsModalOpen] = useState(false);
  const [isConfModalOpen, setIsConfModalOpen] = useState(false);
  const [toast, setToast] = useState();
  const [channelsTotalResults, setChannelsTotalResults] = useState(0);
  const [channelsTotal, setChannelsTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(18);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();

  const createRowsPerPageOptions = (dataLength) => {
    const options = [];
    const maxRows = Math.max(18, dataLength); 
    for (let i = 18; i < maxRows; i += 18) {
      options.push(i);
    }
    if (!options.includes(dataLength)) {
      options.push(dataLength);
    }
    return options;
  };
  
  const rowsPerPageOptions = createRowsPerPageOptions(channelsTotalResults);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0); 
  };

  const fetchChannels = async () => {
    try {
      const result = await channelManager.getChannelsApiPagination(
        selectedCategory.id,
        rowsPerPage,
        currentPage,
        searchInputValue,
        selectedSortType.id
      );
      if (result?.status === 0) {
        setChannels(result.data.channels);
        setCategories(result.data.genres); 
        setChannelsTotalResults(result.data.totalResult); 
        setChannelsTotal(result.data.total); 
      }
    } catch (error) {
      dispatch(errorActions.setErrorMessage("Error fetching channels"));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!searchInputValue) {
      setIsLoading(true); 
    }
    fetchChannels(); 
  }, [searchInputValue, selectedSortType, selectedCategory, rowsPerPage, currentPage]);



  const openChannelModalHandler = async (channel, isEditingValue) => {
    setIsChannelModalOpen(true);
    setIsEditing(isEditingValue);
    setSelectedChannel(channel);
  };

  const menuButtons = [
    {
      id: "modify",
      imagePath: "icons/modify",
      activeImagePath: "icons/modify-active",
      disabled: !selectedCategory || selectedCategory?.name === "Uncategorized",
      action: () => {
        setIsCategoryModalOpen(true);
        setIsEditing(true);
      },
    },
    {
      id: "delete",
      imagePath: "icons/delete",
      activeImagePath: "icons/delete-active",
      disabled: !selectedCategory || selectedCategory?.name === "Uncategorized",
      action: () => setIsConfModalOpen(true),
    },
    {
      id: "add",
      imagePath: "icons/add",
      activeImagePath: "icons/add-active",
      action: () => {
        setIsCategoryModalOpen(true);
      },
    },
  ];

  const refreshCategoryHandler = () => {
    if (selectedCategory !== '') {      
      setSelectedCategory(''); 
    }else{
      fetchChannels();
    }
  };

  const submitCategoryHandler = async (names, icon, rank, id) => {
    setIsLoadingModal(true);
    let result;
    if (isEditing) {
      result = await channelManager.updateCategoryApi(names, icon, rank, id);
    } else {
      result = await channelManager.createCategoryApi(names, icon, rank);
    }
    if (result?.status === 0) {
      setToast({
        messageText: t(
          isEditing
            ? "toast_edit_category_success"
            : "toast_create_category_success",
          { name: names?.en }
        ),
        type: "info-green",
        isDisplayed: true,
      });
      refreshCategoryHandler();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsCategoryModalOpen(false);
    setIsEditing(false);
    setIsLoadingModal(false);
  };

  const submitLinkContentsHandler = async (
    contentIdsTobeAdded,
    contentIdsTobeRemoved,
    selectedItems
  ) => {    
    setIsLoadingModal(true);
    if (contentIdsTobeAdded?.length > 0) {
      const result1 = await channelManager.attachContentsToCatApi({
        catId: selectedCategory?.id,
        liveTvIds: contentIdsTobeAdded,
      });
      if (result1?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result1?.message || result1));
        setIsLoadingModal(false);
        return;
      }
    }
    if (contentIdsTobeRemoved?.length > 0) {
      const result2 = await channelManager.detachContentsFromCatApi({
        liveTvIds: contentIdsTobeRemoved,
        catId: selectedCategory?.id,
      });
      if (result2?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result2?.message || result2));
        setIsLoadingModal(false);
        return;
      }
    }
    if (
      contentIdsTobeAdded?.length === 0 &&
      contentIdsTobeRemoved?.length === 0
    ) {
      setIsLoadingModal(false);
      setIsLinkContentsModalOpen(false);
      return;
    }
    setIsLoadingModal(false);
    setIsLinkContentsModalOpen(false);    

    fetchChannels(); 
  };

  const deleteCategoryHandler = async () => {
    setIsLoadingModal(true);
    const result = await channelManager.deleteCategoryApi({
      catId: selectedCategory?.id,
    });
    if (result?.status === 0) {
      setToast({
        messageText: t("toast_delete_category_success"),
        type: "warning",
        isDisplayed: true,
        catId: selectedCategory?.id,
      });
      refreshCategoryHandler();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsConfModalOpen(false);
    setIsLoadingModal(false);
  };

  const undoDeleteHandler = async () => {
    const result = await channelManager.restoreCategoryApi({
      catId: toast?.catId,
    });
    if (result?.status === 0) {
      refreshCategoryHandler();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  const handleEditRank = async (e, rank, id, categoryId) => {
    const rankNumber = Number(rank)
    
    setIsLodingCard(true)
    if (e.key === "Enter") {

      if (rank === '' || rank === 'e') {
        setIsLodingCard(false)
        dispatch(errorActions.setErrorMessage('Missing mandatory request parameter'));
        return
      }

      if (selectedCategory === '') {
        console.log(selectedCategory,id, rankNumber);
        const result = await channelManager.updateRankGlobal({
          liveTvId: id,
          rank: rankNumber,
        });
        if (result?.status === 0) {
          fetchChannels();
        } else {
          dispatch(errorActions.setErrorMessage(result?.message || result));
        }
      } else {
        const result = await channelManager.updateRankCategory({
          catId: selectedCategory?.id,
          liveTvId: id,
          rank: rankNumber
        });
        if (result?.status === 0) {
          fetchChannels();
        } else {
          dispatch(errorActions.setErrorMessage(result?.message || result));
        }
      }
    }
    setIsLodingCard(false)
  }

  const handleDisplayAll = () => {
    setSelectedCategory(''); 
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);  
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  const handleSortChange = (sortType) => {
    setSelectedSortType(sortType);
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  const handleSearch = (value) => {
    setSearchInputValue(value);
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  const determineType = (category) => {    
    if (category === '' || !category) {
      return 'all_channels'; 
    }
    if (category.id === 0) { return false; }
    return 'channels';
  };


  return isLoading ? (
    <ContentCardSkeletonWithPagination />
  ) : (
    <>
      <MenuCardLayout
        pageTitle={t("liveTv")}
        displayAllNumber={""}
        isDisplayAllSelected={selectedCategory === ''}
        onSelectDisplayAll={handleDisplayAll}
        buttons={menuButtons}
        menuItemsListClassName={classes.menuItemsList}
      >
        <div className={classes.headerContainer}>
          <SearchSortView
            hasSort={true}
            options={SORT_OPTIONS}
            selectedSortType={selectedSortType}
            onSelectSortType={handleSortChange}
            searchInputValue={searchInputValue}
            searchInputPlaceholder={t("searchChannel")}
            onClearSearchIput={() => setSearchInputValue("")}
            onChangeSearchInput={handleSearch}
            expButtonClassName={classes.sortBtn}
          />
          <ButtonIcon
            imagePath={"buttons/add-in"}
            activeImagePath={"buttons/add-in"}
            disabledImagePath={"buttons/add-in-disabled"}
            className={classes.btn}
            isDisabled={!selectedCategory.id}
            onClick={() => setIsLinkContentsModalOpen(true)}
          />
        </div>
        {categories.map((category) => {
          return (
            category.id !== "display_all" && (
              <CategoryItem
                key={category?.id}
                id={category?.id}
                name={
                  category?.name === "Uncategorized"
                    ? category?.name
                    : ContentHelper.getNameByLang(category)
                }
                icon={category?.iconUrl}
                number={category?.channelsCount}
                onClick={() => handleCategorySelect(category)}
                selectedCategoryId={selectedCategory.id}
              />
            )
          );
        })}
        {channels.length > 0 ? (
          <>
            <ul className={classes.list}>
              {channels?.map((channel) => (
                <ContentCard key={channel?.id}
                  isShowView={true}
                  onClickView={() => openChannelModalHandler(channel, true)}
                  content={channel} className={classes.channelCard}
                  type={determineType(selectedCategory)}
                  isModalLoading={isLodingCard}
                  onEditContentRank={(e, rank, id) => handleEditRank(e, rank, id, selectedCategory?.id)}
                />
              ))}
            </ul>
            <div className={classes.paginationFooter}>
            {
              channelsTotalResults > 17 && (
                <CustomPaginationFooter
                  totalItems={channelsTotalResults}
                  rowsPerPage={rowsPerPage}
                  page={currentPage}
                  setPage={handlePageChange}
                  setRowsPerPage={handleRowsPerPageChange}
                  rowsPerPageOptions={rowsPerPageOptions}
                />
              )
            }
          </div>
          </>
        ) : (
          !isLoading && (
            <NoItemsView text={t("noItemsRow")} showCreateText={true} />
          )
        )}
      </MenuCardLayout >
      
        
      {isChannelModalOpen && (
        <ChannelModal
          isModalOpen={isChannelModalOpen}
          onClose={() => {
            setIsChannelModalOpen(false);
            setIsEditing(false);
          }}
          channel={selectedChannel}
          isEditing={isEditing}
        />
      )
      }
      {
        isCategoryModalOpen && (
          <ThemeModal
            isModalOpen={isCategoryModalOpen}
            onClose={() => {
              setIsCategoryModalOpen(false);
              setIsEditing(false);
            }}
            category={
              isEditing ? selectedCategory : null
            }
            isLoadingModal={isLoadingModal}
            onSubmit={submitCategoryHandler}
            hasIcon={true}
          />
        )
      }
      {
        isConfModalOpen && (
          <ConfirmationModal
            isModalOpen={isConfModalOpen}
            onClose={() => setIsConfModalOpen(false)}
            onConfirm={deleteCategoryHandler}
            text={t("delete_type", {
              type: t("cat"),
            })}
            description={t("delete_type_conf_message", {
              type: t("cat"),
            })}
            name={ContentHelper.getNameByLang(selectedCategory)}
            btnConfirmTitle={t("yes")}
            btnCancelTitle={t("cancel")}
            isLoading={isLoadingModal}
          />
        )
      }
      {
        isLinkContentsModalOpen && (
          <LinkContentsModal
            isModalOpen={isLinkContentsModalOpen}
            onClose={() => setIsLinkContentsModalOpen(false)}
            isModalLoading={isLoadingModal}
            typeData={selectedCategory}
            onSubmit={submitLinkContentsHandler}
            title={t("add_contents_to_type", {
              type: ContentHelper.getNameByLang(selectedCategory),
            })}
            api={channelManager.getChannelsListApi}
            getOriginalDataSource={channelManager.getOriginalChannelsList}
            initialContents={channels}
          />
        )
      }
      {
        toast?.isDisplayed && (
          <Toast
            messageText={toast?.messageText}
            type={toast?.type}
            isDisplayed={toast?.isDisplayed}
            onDisplay={(isDisplayed) =>
              setToast((prevState) => {
                return { ...prevState, isDisplayed: isDisplayed };
              })
            }
            onUndo={toast?.type === "warning" ? undoDeleteHandler : null}
          />
        )
      }
    </>
  );
}

export default LiveTV;
