import { Skeleton } from "@mui/material";
import classes from "./CategoryItem.module.scss";

function CategoryItemSkeleton() {
  return (
    <div className={classes.card}>
      <div className={classes.list}>
        {[...Array(5)].map((_, index) => (
          <div key={index} className={classes.listItem}>
            <Skeleton variant="circular" width={40} height={40} className={classes.icon} />
            <Skeleton variant="text" width="60%" height={24} className={classes.name} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategoryItemSkeleton;
