import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./VOD.module.scss";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import ContentCard from "../../../../components/contentCards/contentCard/ContentCard";
import MovieModal from "../../../../components/modals/movieModal/MovieModal";
import Toast from "../../../../components/toast/Toast";
import NoItemsView from "../../../../components/ui/noItemsView/NoItemsView";
import Loader from "../../../../components/ui/loader/Loader";
import SearchSortView from "../../../../components/ui/searchSort/searchSortView/SearchSortView";
import SortHelper from "../../../../utils/SortHelper";
import movieManager from "../../../../backend/managers/MovieManager";
import { errorActions } from "../../../../store/reducers/Error-slice";
import CustomPaginationFooter from "../../../../components/pagination/CustomPaginationFooter";
import ContentCardSkeletonWithPagination from "../../../../components/skeleton/contents/ContentCardSkeletonWithPagination";

function VOD() {
  const SORT_OPTIONS = SortHelper.getContentSortOptions();

  const [categories, setCategories] = useState([]);
  const [vods, setVods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMovie, setSelectedMovie] = useState();
  const [isMovieModalOpen, setIsMovieModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [selectedSortType, setSelectedSortType] = useState(SORT_OPTIONS[0]);
  const [vodsTotalResults, setVodsTotalResults] = useState(0);
  const [vodsTotal, setVodsTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(18);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();

  const createRowsPerPageOptions = (dataLength) => {
    const options = [];
    const maxRows = Math.max(18, dataLength); 
    for (let i = 18; i < maxRows; i += 18) {
      options.push(i);
    }
    if (!options.includes(dataLength)) {
      options.push(dataLength);
    }
    return options;
  };
  
  const rowsPerPageOptions = createRowsPerPageOptions(vodsTotalResults);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0); 
  };

  const fetchVods = async () => {
    try {
      const result = await movieManager.getMoviesCategoriesApiPagination(
        selectedCategory.id,
        rowsPerPage,
        currentPage,
        searchInputValue,
        selectedSortType.id
      );
      console.log("result",result);
      
      if (result?.status === 0) {
        setVods(result.data.movies);
        setCategories(result.data.categories); 
        setVodsTotal(result.data.total); 
        setVodsTotalResults(result.data.totalResult); 
        
      }
    } catch (error) {
      console.error("Fetch VODs error:", error); 
      dispatch(errorActions.setErrorMessage("Error fetching vods"));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!searchInputValue) {
      setIsLoading(true); 
    }
    fetchVods(); 
  }, [searchInputValue, selectedSortType, selectedCategory, rowsPerPage, currentPage]);

  const openMovieModalHandler = () => {
    setIsMovieModalOpen(true);
  };


  const closeMovieModalHandler = () => {
    setIsMovieModalOpen(false);
    setSelectedMovie();
    setIsEditing(false);
  };


  const openEditMovieModalHandler = async (movie) => {
    setSelectedMovie(movie);
    setIsMovieModalOpen(true);
    setIsEditing(true);
  };

  const handleDisplayAll = () => {
    setSelectedCategory(''); 
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);  
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  const handleSortChange = (sortType) => {
    setSelectedSortType(sortType);
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };

  const handleSearch = (value) => {
    setSearchInputValue(value);
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(18); // Reset item before the API call
  };


  return (
    <div className={classes.page}>
      {isLoading ? (
        <ContentCardSkeletonWithPagination />
      ) : (
        <>
         
          <MenuCardLayout
            // buttons={menuButtons}
            pageTitle={t("movies") + " (" + vodsTotalResults + ")"}
            displayAllNumber={vodsTotal}
            isDisplayAllSelected={selectedCategory === ''}
            onSelectDisplayAll={handleDisplayAll}
          >
            <SearchSortView
              hasSort={true}
              options={SORT_OPTIONS}
              selectedSortType={selectedSortType}
              onSelectSortType={handleSortChange}
              searchInputValue={searchInputValue}
              searchInputPlaceholder={t("searchMovie")}
              onClearSearchIput={() => setSearchInputValue("")}
              onChangeSearchInput={handleSearch}
            />
            {categories.map((category) => {
              return (
                category.id !== "display_all" && (
                  <CategoryItem
                    key={category?.id}
                    id={category?.id}
                    name={
                      category?.id !== "display_all"
                        ? category?.name
                        : t(category?.id)
                    }
                    number={category?.moviesCount}
                    onClick={() => handleCategorySelect(category)}
                    selectedCategoryId={selectedCategory?.id}
                  />
                )
              );
            })}
            {vods.length > 0 ? (
              <>
                <ul className={classes.vodList}>
                  {vods?.map((movie) => (
                    <ContentCard
                      className={classes.itemCard}
                      key={movie?.id}
                      content={movieManager.getLocalVodById(movie?.id)}
                      onEdit={() => openEditMovieModalHandler(movie)}
                      ghostName={t("VOD")}
                    />  
                  ))}
                </ul>
                <div className={classes.paginationFooter}>
                  {
                    vodsTotalResults > 17 && (
                      <CustomPaginationFooter
                        totalItems={vodsTotalResults}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        setPage={handlePageChange}
                        setRowsPerPage={handleRowsPerPageChange}
                        rowsPerPageOptions={rowsPerPageOptions}
                      />
                    )
                  }
                </div>
              </>
            ) : (
              !isLoading && <NoItemsView onClick={openMovieModalHandler} />
            )}
          </MenuCardLayout>
          {isMovieModalOpen && (
            <MovieModal
              isModalOpen={isMovieModalOpen}
              onClose={closeMovieModalHandler}
              isEditing={isEditing}
              movie={isEditing && selectedMovie}
              disabled={true}
            />
          )}
          {showToast && (
            <Toast
              isDisplayed={showToast}
              type="warning"
              messageText={messageText}
              onDisplay={(isDisplayed) => setShowToast(isDisplayed)}
            />
          )}
          
        </>
      )}
    </div>
  );
}

export default VOD;
