import React, { useState } from "react";
import PropTypes from "prop-types"; 
import ButtonIcon from "../ui/buttonIcon/ButtonIcon";
import classes from "./DataViewToggle.module.scss";

const DataViewToggleComponent = ({
  items = [], 
  itemsLabel = "results",
  totalResults,
  currentSearchSortView, // Pass the search and sort component
  currentListView,       // Pass the card or list view component
  currentTableView,      // Pass the table view component
  currentFooter,         // Pass the pagination component
  noResultsText = "No items available",
  onDisplayGridChange,
  displayGridState
}) => {
  const [displayGrid, setDisplayGrid] = useState(displayGridState);

  const handleToggleDisplayGrid = (newDisplayGrid) => {
    setDisplayGrid(newDisplayGrid);
    if (onDisplayGridChange) {
      onDisplayGridChange(newDisplayGrid); 
    }
  };

  return (
    <div className={classes.main}>
      <div className={classes.headerContainer}>
        {/* Search and Sort View */}
        {currentSearchSortView && currentSearchSortView}
      </div>
      <div className={classes.scrollContainer}>
        <div className={classes.scrollHeader}>
          <p className={classes.totalItems}>
            {items.length} {itemsLabel}
          </p>

          <div className={classes.actionsContainer}>
            <ButtonIcon
              imagePath={`icons/card-grid${!displayGrid ? "-active" : ""}`}
              className={`${classes.btnGrid} ${!displayGrid && classes.btnActive}`}
              onClick={() => handleToggleDisplayGrid(false)}
              isSvg={true}
            />
            <ButtonIcon
              imagePath={`icons/tabs${displayGrid ? "-active" : ""}`}
              className={displayGrid ? classes.btnActive : ""}
              onClick={() => handleToggleDisplayGrid(true)}
              isSvg={true}
            />
          </div>
        </div>
        <div className={classes.scrollContent}>
          {items.length > 0 ? (
            !displayGrid ? (
              // Render Card/List View
              <ul className={classes.list}>
                {currentListView ? currentListView : null}
              </ul>
            ) : (
              // Render Table View
              currentTableView ? currentTableView : null
            )
          ) : (
            <p>{noResultsText}</p>
          )}
        </div>
        {totalResults > 7 && (
          <div className={classes.scrollFooter}>
            {/* Footer or Pagination */}
            {currentFooter && currentFooter}
          </div>
        )}
      </div>
    </div>
  );
};

DataViewToggleComponent.propTypes = {
  items: PropTypes.array.isRequired, 
  itemsLabel: PropTypes.string,
  totalResults: PropTypes.number.isRequired,
  currentSearchSortView: PropTypes.node,
  currentListView: PropTypes.node,
  currentTableView: PropTypes.node,
  currentFooter: PropTypes.node,
  noResultsText: PropTypes.string,
  onDisplayGridChange: PropTypes.func,
  displayGridState: PropTypes.bool,
};

export default DataViewToggleComponent;
