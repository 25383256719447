import React from "react";
import Skeleton from "@mui/material/Skeleton"; 
import classes from "./ContentCardSkeletonWithPagination.module.scss"; 
import CategoryItemSkeleton from "./component/CategoryItemSkeleton";
import ContentCardSkeleton from "./component/ContentCardSkeleton";

const ContentCardSkeletonWithPagination = () => {
  return (
    <>
      <div className={classes.skeletonRow}>
        <Skeleton
          variant="text"
          width="15%"
          height={60}
          className={classes.skeletonItem}
        />
        <Skeleton
          variant="search"
          width="20%"
          height={40}
          className={classes.skeletonItemSearch}
        />
        <Skeleton variant="search" width="3%" height={40} />
      </div>
      <div className={classes.skeletonContainer}>
        <div className={classes.leftSide}>
          <CategoryItemSkeleton />
        </div>

        <div className={classes.rightSide}>
          <div className={classes.gridContainer}>
            <ContentCardSkeleton />
          </div>
          <div className={classes.paginationSkeleton}>
            <Skeleton
              variant="text"
              width={60}
              height={40}
              className={classes.paginationItem}
            />
            <Skeleton
              variant="circle"
              width={40}
              height={30}
              className={classes.paginationItem}
            />
            <Skeleton
              variant="text"
              width={70}
              height={40}
              className={classes.paginationItem}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentCardSkeletonWithPagination;
