import ContentHelper from "../../utils/ContentHelper";
import { CONTENT_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";

let catMap = {},
  seriesMap = {},
  searchCatMap = {};
let categories = [],
  categoriesWithDisplayAll = [];

class SerieManager {
  getSeriesCategoriesApi = async (signal) => {
    try {
      const response = await loaderCenter.get(CONTENT_API.SERIES, signal);

      const responseData = await response.json();
      const data = responseData.data;

      const allMedia = [];
      const allMediaMap = {};
      if (data && data.length > 0) {
        data.forEach((cat) => {
          if (!catMap[cat.id]) {
            catMap[cat.id] = cat;
            // cat.pickerName = ContentHelper.getNameByLang(cat);
            cat.pickerName = cat.name;
            categories.push(cat);
          }
          cat?.series?.forEach((serie) => {
            serie.pickerName = ContentHelper.getNameByLang(serie);
            if (!allMediaMap[serie.id]) {
              allMedia.push(serie);
              allMediaMap[serie.id] = serie;
            }
          });
        });
      }

      const displayAllObject = {
        id: "display_all",
        series: allMedia,
      };
      data?.unshift(displayAllObject);
      catMap[displayAllObject.id] = displayAllObject;
      seriesMap = allMediaMap;

      categoriesWithDisplayAll = data;

      return {
        data: data,
        status: responseData.status,
        message: responseData?.message,
      };
    } catch (err) {
      throw err;
    }
  };

  getSeriesCategoriesApiPagination = async (categoryId,rowsPerPage, currentPage, searchInputValue, selectedSortType, signal) => {
    try {
      categoryId = categoryId ?? "";           
      rowsPerPage = rowsPerPage ?? 30;           
      currentPage = currentPage ?? 0;            
      searchInputValue = searchInputValue ?? "";  
      selectedSortType = selectedSortType ?? "a_to_z";

      const response = await loaderCenter.get(CONTENT_API.SERIES_PAGINATION(categoryId,rowsPerPage, currentPage,searchInputValue,selectedSortType), signal);

      const responseData = await response.json();
      

      const {
        genres = [],
        series = [],
        lastPage,
        pageNumber,
        perPage,
        total,
        totalResult,
      } = responseData.data;

      const { status } = responseData;

      if (!Array.isArray(genres) || !Array.isArray(series)) {
        throw new Error("Genres or series are not in the correct format");
      }
      
      const allMedia = [];
      const allMediaMap = {};
  
      genres.forEach((genre) => {
        if (!catMap[genre.id]) {
          catMap[genre.id] = genre;
          genre.pickerName = genre.name;
          categories.push(genre);
        }
      });
      series?.forEach((serie) => {
        serie.pickerName = ContentHelper.getNameByLang(serie);
        if (!allMediaMap[serie.id]) {
          allMedia.push(serie);
          allMediaMap[serie.id] = serie;
        }
      });

      const displayAllObject = {
        id: "display_all",
        series: allMedia,
      };
  
      categories.unshift(displayAllObject); 
      catMap[displayAllObject.id] = displayAllObject;
      seriesMap = allMediaMap;
  
      categoriesWithDisplayAll = categories;
      

      return {
        data: {
          genres,
          series,
          lastPage,
          pageNumber,
          perPage,
          total,
          totalResult
        },
        status: responseData.status,
        message: responseData?.message,
      };
    } catch (err) {
      throw err;
    }
  };

  

  getLocalCategoryById(id) {
    return catMap[id];
  }

  getSerieById = async (id) => {
    try {
      const response = await loaderCenter.get(CONTENT_API.SERIE(id));

      const responseData = await response.json();
      const data = responseData.data;

      data.genre = data?.genre?.map((cat) => {
        cat["id"] = categories.find((c) => c?.name === cat?.name)?.id;
        return cat;
      });

      return data;
    } catch (err) {
      throw err;
    }
  };

  getLocalSeriesById(id) {
    if (id && seriesMap && seriesMap[id]) {
      return seriesMap[id];
    }
  }

  //   getLocalCategoryById(id) {
  //     return catMap[id];
  //   }

  //   getVodById = async (id) => {
  //     try {
  //       const response = await loaderCenter.get(CONTENT_API.MOVIE(id));

  //       const responseData = await response.json();
  //       const data = responseData.data;

  //       data.genre = data?.genre?.map((cat) => {
  //         cat["id"] = categories.find((c) => c?.name === cat?.name)?.id;
  //         return cat;
  //       });

  //       return data;
  //     } catch (err) {
  //       throw err;
  //     }
  //   };

  getCategories() {
    return categories;
  }

  searchLocally(inputValue) {
    const searchCats = [];
    const cats = JSON.parse(JSON.stringify(categoriesWithDisplayAll));
    cats.forEach((cat) => {
      cat.series = cat.series.filter((serie) =>
        serie?.pickerName.toLowerCase().includes(inputValue.toLowerCase())
      );
      searchCats.push(cat);
    });

    searchCatMap = {};
    searchCats.forEach((cat) => {
      if (!searchCatMap[cat.id]) {
        searchCatMap[cat.id] = cat;
      }
    });
    return searchCats;
  }

  getLocalSearchedCategoryById(id) {
    return searchCatMap[id];
  }
}

const serieManager = new SerieManager();

export default serieManager;
