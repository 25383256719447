import { CONTENT_API } from "../ApiUrls";
import ContentHelper from "../../utils/ContentHelper";
import loaderCenter from "../LoaderCenter";

let catMap = {},
  vodMap = {},
  searchCatMap = {};
let categories = [],
  categoriesWithDisplayAll = [];

class MovieManager {
  getMoviesCategoriesApi = async (signal) => {
    try {
      const response = await loaderCenter.get(CONTENT_API.MOVIES, signal);

      const responseData = await response.json();
      const data = responseData.data;

      const allMedia = [];
      const allMediaMap = {};
      if (data && data.length > 0) {
        data.forEach((cat) => {
          if (!catMap[cat.id]) {
            catMap[cat.id] = cat;
            // cat.pickerName = ContentHelper.getNameByLang(cat);
            cat.pickerName = cat.name;
            categories.push(cat);
          }
          cat?.movies?.forEach((vod) => {
            vod.pickerName = ContentHelper.getNameByLang(vod);
            if (!allMediaMap[vod.id]) {
              allMedia.push(vod);
              allMediaMap[vod.id] = vod;
            }
          });
        });
      }

      const displayAllObject = {
        id: "display_all",
        movies: allMedia,
      };
      data?.unshift(displayAllObject);
      catMap[displayAllObject.id] = displayAllObject;
      vodMap = allMediaMap;

      categoriesWithDisplayAll = data;

      return {
        data: data,
        message: responseData?.message,
        status: responseData?.status,
      };
    } catch (err) {
      throw err;
    }
  };

  getMoviesCategoriesApiPagination = async (categoryId,rowsPerPage, currentPage, searchInputValue, selectedSortType, signal) => {
    try {
      categoryId = categoryId ?? "";           
      rowsPerPage = rowsPerPage ?? 30;           
      currentPage = currentPage ?? 0;            
      searchInputValue = searchInputValue ?? "";  
      selectedSortType = selectedSortType ?? "a_to_z";

      const response = await loaderCenter.get(CONTENT_API.MOVIES_PAGINATION(categoryId,rowsPerPage, currentPage,searchInputValue,selectedSortType), signal);

      const responseData = await response.json();
      
      const {
        genres = [],
        movies = [],
        lastPage,
        pageNumber,
        perPage,
        total,
        totalResult,
      } = responseData.data;
      const { status } = responseData;
    
      if (!Array.isArray(genres) || !Array.isArray(movies)) {
        throw new Error("Genres or movies are not in the correct format");
      }
  
      const allMedia = [];
      const allMediaMap = {};
  
      movies.forEach((vod) => {
        vod.pickerName = ContentHelper.getNameByLang(vod);
        if (!allMediaMap[vod.id]) {
          allMedia.push(vod);
          allMediaMap[vod.id] = vod;
        }
      });
  
      categories = genres.map((genre) => ({
        ...genre,
        pickerName: genre.name,
      }));
  
      const displayAllObject = {
        id: "display_all",
        movies: allMedia,
      };
  
      categories.unshift(displayAllObject);
      const catMap = { [displayAllObject.id]: displayAllObject };
      categories.forEach((cat) => (catMap[cat.id] = cat));
      vodMap = allMediaMap;
  
      return {
        data: { categories, movies, lastPage, pageNumber, perPage, total, totalResult },
        message: responseData?.message,
        status: status,
      };
    } catch (err) {
      throw err;
    }
  };

  getLocalVodById(id) {
    if (id && vodMap && vodMap[id]) {
      return vodMap[id];
    }
  }

  getLocalCategoryById(id) {
    return catMap[id];
  }

  getVodById = async (id) => {
    try {
      const response = await loaderCenter.get(CONTENT_API.MOVIE(id));

      const responseData = await response.json();
      const data = responseData.data;

      data.genre = data?.genre?.map((cat) => {
        cat["id"] = categories.find((c) => c?.name === cat?.name)?.id;
        return cat;
      });

      return data;
    } catch (err) {
      throw err;
    }
  };

  getCategories() {
    return categories;
  }

  searchLocally(inputValue) {
    const searchedMoviesCats = [];
    const cats = JSON.parse(JSON.stringify(categoriesWithDisplayAll));
    cats.forEach((cat) => {
      cat.movies = cat.movies.filter((movie) =>
        movie?.pickerName.toLowerCase().includes(inputValue.toLowerCase())
      );
      searchedMoviesCats.push(cat);
    });

    searchCatMap = {};
    searchedMoviesCats.forEach((cat) => {
      if (!searchCatMap[cat.id]) {
        searchCatMap[cat.id] = cat;
      }
    });
    return searchedMoviesCats;
  }

  getLocalSearchedCategoryById(id) {
    return searchCatMap[id];
  }
}

const movieManager = new MovieManager();

export default movieManager;
