import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";

import classes from "./Subscriptions.module.scss";
import CustomSelectInput from "../../../components/ui/inputs/customSelectInput/CustomSelectInput";
import Circle from "../../../components/ui/circle/Circle";
import SearchSortView from "../../../components/ui/searchSort/searchSortView/SearchSortView";
import SubscriptionCard from "../../../components/contentCards/subscriptionCard/SubscriptionCard";
import customerManager from "../../../backend/managers/CustomerManager";
import SubscriptionModal from "../../../components/modals/subscriptionModal/SubscriptionModal";
import subscriptionManager from "../../../backend/managers/SubscriptionManager";
import Loader from "../../../components/ui/loader/Loader";
import Toast from "../../../components/toast/Toast";
import SubscriptionTable from "./subscriptionTable/SubscriptionTable";
import CustomPaginationFooter from "../../../components/pagination/CustomPaginationFooter";
import ConfirmationModal from "../../../components/modals/confirmationModal/ConfirmationModal";
import DataViewToggleComponent from "../../../components/dataViewToggle/DataViewToggle";

function Subscriptions() {
  const { customerId } = useParams();

  let customers = customerManager.getCustomers();

  const OPTIONS = subscriptionManager.getOptions();
  const headerSubscriptionData =
    subscriptionManager.getHeaderSubscriptionData();

  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsTotalResults, setSubscriptionsTotalResults] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedCustomerId, setSelectedCustomerId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [selectedFilter, setSelectedFilter] = useState(OPTIONS && OPTIONS[0]);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeviveModalOpen, setIsDeviveModalOpen] = useState(false);
  const [isResendPasswordModalOpen, setIsResendPasswordModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [displayGrid, setDisplayGrid] = useState(false);

  // Add state for pagination
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(0);


  const permissions = JSON.parse(localStorage.getItem("userData"))?.permissions;
  const canEdit = permissions?.devices?.edit;

  const navigate = useNavigate();

  const getSubscriptionsApiHandler = async () => {
    const result = await subscriptionManager.getSubscriptionsApi(customerId,selectedFilter.id,rowsPerPage,currentPage,searchInputValue);
    if (result?.status === 0) {
      setSubscriptions(result?.data?.subscribersList);
      setSubscriptionsTotalResults(result?.data?.totalResutls)
    }
    setIsLoading(false);
  };

  useEffect(() => {    
    setCurrentPage(0);
    const getCustomerSubscriptions = async () => {
      setIsLoading(true);
      const result = await customerManager.getCustomersApi();
      customers = result?.data?.subscribersList;
      const customer = customerManager.getCustomerById(customerId);
      setSelectedCustomer(customer);
    };
    getCustomerSubscriptions();
  }, [customerId]);

  const backHandler = () => {
    navigate("/customers");
  };

  const openSubscriptionModalHandler = (sub, isEditing) => {
    setIsEditing(isEditing);
    setSelectedSubscription(sub);
    setIsDeviveModalOpen(true);
  };

  const openResendPasswordModalHandler = (sub, isResendPassword) => {
    setSelectedSubscription(sub);
    setIsResendPasswordModalOpen(isResendPassword);
  };

  const resendPasswordHandler = async (subscription) => {
    const result = await subscriptionManager.resendSubscriptionPasswordApi(
      subscription ? subscription.id : selectedSubscription?.id
    );
    if (result.status === 0) {
      setIsDeviveModalOpen(false);
      setIsResendPasswordModalOpen(false);
      setMessageText(
        t("toast_resend_password_success", {
          mac: subscription ? subscription.macAddress : selectedSubscription?.macAddress,
        })
      );
      setShowToast(true);
    }
  };

  const unlinkSubscriptionHandler = async () => {
    const result = await subscriptionManager.unlinkSubscriptionApi(
      selectedSubscription?.id
    );
    if (result.status === 0) {
      setIsDeviveModalOpen(false);
      setIsResendPasswordModalOpen(false);
      setMessageText(
        t("toast_unlink_subscription", {
          mac: selectedSubscription?.macAddress,
        })
      );
      setShowToast(true);
    }
    setIsLoading(true);
    getSubscriptionsApiHandler();
  };

  const createRowsPerPageOptions = (dataLength) => {
    const options = [];
    const maxRows = Math.max(8, dataLength); 
    for (let i = 8; i < maxRows; i += 8) {
      options.push(i);
    }
    if (!options.includes(dataLength)) {
      options.push(dataLength);
    }
    return options;
  };
  
  const rowsPerPageOptions = createRowsPerPageOptions(subscriptionsTotalResults);

  useEffect(() => {
       getSubscriptionsApiHandler();
  }, [selectedCustomerId,searchInputValue, currentPage, rowsPerPage, selectedFilter]); 

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0); 
  };

  const handleSearch = (value) => {
    setSearchInputValue(value);
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(8); // Reset items before the API call
  };

  const handleSelectedFilter = (value) => {
    setSelectedFilter(value);
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(8); // Reset items before the API call
  };

  const handleSelectedCustomer = (event) => {
    setCurrentPage(0); // Reset page before the API call
    setRowsPerPage(8); // Reset items before the API call
    const customerId = event.target.value;
    setSelectedCustomerId(customerId); 
    navigate(`../customers/${customerId}`); 
  };


  return (
    <>
      <header className={classes.header}>
        <div className={classes.title}>
          <span onClick={backHandler}>
            {t("customers")}
            <span className={classes.char}>{"<"}</span>
          </span>
          <h2>{t("company")}</h2>
        </div>
        <div className={classes.row}>
          <CustomSelectInput
            className={classes.customSelect}
            optionValueField={"customerName"}
            options={customers}
            value={selectedCustomer}
            onChange={handleSelectedCustomer}
          />
          <div className={classes.info}>
            <span>
              {t("subscriptions") +
                ": " +
                headerSubscriptionData?.active +
                " / " +
                headerSubscriptionData?.total}
            </span>
            <div className={classes.verticalLine} />
            <div className={classes.offersInfo}>
              <span>
                {t("basic_offers") + ` (${headerSubscriptionData?.basic})`}
              </span>
              <Circle className={classes.bullet} color="var(--neutral-white)" />
              <span>
                {t("advance_offers") + ` (${headerSubscriptionData?.advanced})`}
              </span>
              <Circle className={classes.bullet} color="var(--neutral-white)" />
              <span>
                {t("premium_offers") + ` (${headerSubscriptionData?.premium})`}
              </span>
            </div>
          </div>
        </div>
      </header>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={classes.container}>
          <DataViewToggleComponent
            items={subscriptions}
            itemsLabel={t("results")}
            totalResults={subscriptionsTotalResults}
            rowsPerPageOptions={rowsPerPageOptions}
            currentSearchSortView={
              <SearchSortView
                hasSort={true}
                className={classes.searchSortView}
                expButtonClassName={classes.expButton}
                searchInputClassName={classes.searchInput}
                options={OPTIONS}
                selectedSortType={selectedFilter}
                onSelectSortType={handleSelectedFilter}
                searchInputValue={searchInputValue}
                searchInputPlaceholder={t("searchSubscription")}
                onClearSearchIput={() => setSearchInputValue("")}
                onChangeSearchInput={handleSearch}
              />
            }
            currentListView={
              subscriptions.map((subscription) => (
                <SubscriptionCard
                  key={subscription.id}
                  subscription={subscription}
                  onResendPassword={() => openResendPasswordModalHandler(subscription, true)}
                  onEdit={() => openSubscriptionModalHandler(subscription, true)}
                  canEdit={canEdit}
                />
              ))
            }
            currentTableView={
              <SubscriptionTable
                data={subscriptions}
                onClick={(type, subscription) => {
                  if (type === "submit") {
                    openResendPasswordModalHandler(subscription, true);
                  } else if (type === "edit") {
                    openSubscriptionModalHandler(subscription, true);
                  }
                }}
              />
            }
            currentFooter={
              <CustomPaginationFooter
                totalItems={subscriptionsTotalResults}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                setPage={handlePageChange}
                setRowsPerPage={handleRowsPerPageChange}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            }
            noResultsText={t("No subscriptions available")}
            displayGridState={displayGrid}
          />
        </div>
      )}
      {isDeviveModalOpen && (
        <SubscriptionModal
          isModalOpen={isDeviveModalOpen}
          onClose={() => setIsDeviveModalOpen(false)}
          isEditing={isEditing}
          subscription={selectedSubscription}
          onResendPassword={() => resendPasswordHandler()}
          onUnlinkSubscription={unlinkSubscriptionHandler}
        />
      )}
      {isResendPasswordModalOpen && (
        <ConfirmationModal
          isModalOpen={isResendPasswordModalOpen}
          onClose={() => setIsResendPasswordModalOpen(false)}
          text={t("resend_password")}
          description={t("resend_password_confirmation_Message")}
          btnConfirmClassName={`${classes.btnConfirm}`}
          btnConfirmTitle={t("resend")}
          btnCancelTitle={t("cancel")}
          onConfirm={() => resendPasswordHandler(selectedSubscription)}
        />
      )}
      {showToast && (
        <Toast
          isDisplayed={showToast}
          type="info"
          messageText={messageText}
          onDisplay={(isDisplayed) => setShowToast(isDisplayed)}
        />
      )}
    </>
  );
}

export default Subscriptions;
