import { Skeleton } from "@mui/material";
import classes from "./ContentCard.module.scss";
import Card from "../../../ui/card/Card";


function ContentCardSkeleton({ type }) {
  return (
    <>
      {[...Array(12)].map((_, index) => (
        <Card key={index} className={`${classes.card} ${classes.skeleton}`}>
          <Skeleton
            variant="rectangular"
            className={classes.image}
            style={{
              height: type === "vertical" ? "320px" : "200px",
              width: type === "vertical" ? "200px" : "100%",
            }}
          />
        </Card>
      ))}
    </>
  );
}

export default ContentCardSkeleton;
