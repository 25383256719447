import { CONTENT_API } from "../ApiUrls";
import ContentHelper from "../../utils/ContentHelper";
import loaderCenter from "../LoaderCenter";
import Cookies from "js-cookie";

let catMap = {},
  epgMap = {},
  searchCatMap = {},
  categories = [],
  categoriesWithoutDisplayAll = [],
  categoriesWithDisplayAll = [],
  CHANNELS_LIST = [],
  EPG_CHANNELS = [];

class ChannelManager {
  getChannelsApi = async () => {
    try {
      const response = await loaderCenter.get(CONTENT_API.CHANNELS);

      const responseData = await response.json();
      const data = responseData?.data;

      categories = [];
      categoriesWithoutDisplayAll = [];
      catMap = {};
      const allMedia = [];
      const allMediaMap = {};
      if (data && data?.length > 0) {
        data.forEach((cat) => {
          if (!catMap[cat.id]) {
            catMap[cat.id] = cat;
            cat.pickerName = cat.name?.en || cat?.name;
            categories.push(cat);
            categoriesWithoutDisplayAll.push(cat);
          }
          cat?.channels?.forEach((channel) => {
            channel.pickerName = ContentHelper.getNameByLang(channel);
            if (!allMediaMap[channel.id]) {
              allMedia.push(channel);
              allMediaMap[channel.id] = channel;
            }
          });
        });
      }

      const displayAllObject = {
        id: "display_all",
        channels: allMedia,
      };
      data?.unshift(displayAllObject);
      catMap[displayAllObject.id] = displayAllObject;
      // catMap = allMediaMap;

      categoriesWithDisplayAll = data;

      responseData.data = data;

      return responseData;
    } catch (err) {
      return err;
    }
  };

  getChannelsApiPagination = async (categoryId,rowsPerPage, currentPage, searchInputValue, selectedSortType,signal) => {
    try {
      categoryId = categoryId ?? "";           
      rowsPerPage = rowsPerPage ?? 30;           
      currentPage = currentPage ?? 0;            
      searchInputValue = searchInputValue ?? "";  
      selectedSortType = selectedSortType ?? "higher_rank";

      const response = await loaderCenter.get(CONTENT_API.CHANNELS_PAGINATION(categoryId,rowsPerPage, currentPage,searchInputValue,selectedSortType),signal);

      const responseData = await response.json();      

      categories = [];
      categoriesWithoutDisplayAll = [];
      catMap = {};
      const allMedia = [];
      const allMediaMap = {};
      
      const data = responseData?.data;
      const genres = data?.genres;
      const channels = data?.channels;
      

      if (genres && genres.length > 0) {
        genres.forEach((genre) => {
          if (!catMap[genre.id]) {
            genre.pickerName = genre.name?.en || genre.name; 
            catMap[genre.id] = genre;
            categories.push(genre);
            categoriesWithoutDisplayAll.push(genre);
          }
        });
      }
  
      if (channels && channels.length > 0) {
        channels.forEach((channel) => {
          channel.pickerName = ContentHelper.getNameByLang(channel);
          if (!allMediaMap[channel.id]) {
            allMedia.push(channel);
            allMediaMap[channel.id] = channel;
          }
        });
      }

      const displayAllObject = {
        id: "display_all",
        channels: allMedia,
      };
      categories?.unshift(displayAllObject);
      catMap[displayAllObject.id] = displayAllObject;

      categoriesWithDisplayAll = categories;            
      
      return responseData;
    } catch (err) {
      return err;
    }
  };

  getLocalCategoryById(id) {
    return catMap[id];
  }

  getCategoriesWithoutDisplayAll() {
    return categoriesWithoutDisplayAll;
  }

  getCatById = (catId) => {
    return catMap[catId];
  };

  getChannelByIdApi = async (channelId) => {
    try {
      const response = await loaderCenter.get(CONTENT_API.CHANNEL(channelId));

      const responseData = await response.json();

      return responseData?.data;
    } catch (err) {
      return err;
    }
  };
  updateRankCategory = async (request) => {
    try {
      const response = await loaderCenter.post(CONTENT_API.UPDATE_RANK_CATEGORY, request);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  }
  updateRankGlobal = async (request) => {
    try {
      const response = await loaderCenter.post(CONTENT_API.UPDATE_RANK_GLOBAL, request);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  }

  createCategoryApi = async (names, icon, rank) => {
    try {
      const formData = new FormData();
      formData.append("icon", icon);
      formData.append("jsonData", JSON.stringify({ name: names, order: rank }));
      const response = await fetch(CONTENT_API.CREATE_CHANNELS_CATEGORY, {
        headers: {
          Authorization: `${Cookies.get("token")}`,
        },
        method: "POST",
        body: formData,
      });

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  updateCategoryApi = async (names, icon = null, rank, id) => {
    try {
      const formData = new FormData();
      icon && formData.append("icon", icon);
      formData.append(
        "jsonData",
        JSON.stringify({ name: names, order: rank, id: id })
      );
      const response = await fetch(CONTENT_API.UPDATE_CHANNELS_CATEGORY, {
        headers: {
          Authorization: `${Cookies.get("token")}`,
        },
        method: "POST",
        body: formData,
      });

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  deleteCategoryApi = async (catId) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.DELETE_CHANNELS_CATEGORY,
        catId
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  getChannelsListApi = async () => {
    try {
      const response = await loaderCenter.get(CONTENT_API.CHANNELS_LIST);

      const responseData = await response.json();
      const data = responseData?.data;

      if (responseData?.data) {
        responseData.data = data.map((channel) => {
          return {
            ...channel,
            pickerName: ContentHelper.getNameByLang(channel),
          };
        });
      }
      CHANNELS_LIST = responseData?.data;

      return responseData;
    } catch (err) {
      return err;
    }
  };

  getOriginalChannelsList = () => {
    return CHANNELS_LIST;
  };

  attachContentsToCatApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.ATTACH_CONTENT_TO_CAT,
        data
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  detachContentsFromCatApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.DETACH_CONTENT_FROM_CAT,
        data
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  getEpgChannelsApi = async () => {
    try {
      const response = await loaderCenter.get(CONTENT_API.EPG_CHANNELS);

      const responseData = await response.json();
      EPG_CHANNELS = responseData?.data;

      EPG_CHANNELS?.forEach((epg) => {
        if (!epgMap[epg?.channelid]) {
          epgMap[epg.channelid] = epg;
        }
      });

      return responseData;
    } catch (err) {
      return err;
    }
  };

  getEpgChannels = () => {
    return EPG_CHANNELS;
  };

  getEpgByChannelId = (channelId) => {
    return epgMap[channelId];
  };

  attachEpgChannelApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.ATTACH_EPG_CHANNEL,
        data
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  detachEpgChannelApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.DETACH_EPG_CHANNEL,
        data
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  searchLocally(inputValue) {
    const searchCats = [];
    const cats = JSON.parse(JSON.stringify(categoriesWithDisplayAll));
    cats.forEach((cat) => {
      cat.channels = cat.channels.filter((channel) =>
        channel?.pickerName.toLowerCase().includes(inputValue.toLowerCase())
      );
      searchCats.push(cat);
    });

    searchCatMap = {};
    searchCats.forEach((cat) => {
      if (!searchCatMap[cat.id]) {
        searchCatMap[cat.id] = cat;
      }
    });
    return searchCats;
  }

  getLocalSearchedCategoryById(id) {
    return searchCatMap[id];
  }

  restoreCategoryApi = async (catId) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.RESTORE_CHANNELS_CATEGORY,
        catId
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };
}

const channelManager = new ChannelManager();

export default channelManager;
