import { useState } from "react";
import { t } from "i18next";

import classes from "./SubscriptionCard.module.scss";
import Card from "../../ui/card/Card";
import Button from "../../ui/button/Button";
import DateFormatSubscription from "../../../pages/dashboard/customers/dateFormat/DateFormatSubscription";
import { Tooltip } from '@mui/material';
import sendIcon from "../../../assets/img/icons/sub-send.svg";
import unLinkedIcon from "../../../assets/img/icons/sub-disconnect.svg";

function SubscriptionCard({ subscription, onEdit, onResendPassword, canEdit }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const isConnected = !!subscription?.macAddress; 

  return (
    <Card className={`${classes.card} ${isConnected ? classes.connected : classes.pending}`}>
      <div className={classes.header}>
        <h3 className= {`${classes.deviceName} ${classes.obfuscated}`}>{subscription.subscriberName}</h3>
        
        <div className={classes.iconContainer}>
          {isConnected ? <div
            className={classes.infoIcon}
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: subscription.isActive ? '#08c34b' : (subscription.isActive === null ? '#858b99' : '#d82316'), // Using gray for disabled status
              marginRight: '8px'
            }}
          /> : <img
              src={require("../../../assets/img/icons/info.png")}
              alt={t("info")}
              className={classes.infoIcon}
              onClick={() => setShowTooltip(true)}
            />
          }
          
        </div>
      </div>

      {isConnected ? (
        <div className={classes.connectedContent}>
          <div className={classes.row}>
            <label className={classes.label}>{t("Offer")}</label>
            <span className={classes.span}>{subscription.offer || t("null")}</span>
          </div>
          <div className={classes.row}>
            <label className={classes.label}>{t("Android_ID")}</label>
            <span className={classes.span}>{subscription.macAddress || t("null")}</span>
          </div>
          <div className={classes.row}>
            <label className={classes.label}>{t("Login_ID")}</label>
            <span className={classes.span}>{subscription.loginName || t("null")}</span>
          </div>
          <div className={classes.row}>
            <label className={classes.label}>{t("ref_number")}</label>
            <span className={classes.span}>{subscription.refnumber || t("null")}</span>
          </div>
          <div className={classes.row}>
            <label className={classes.label}>{t("email")}</label>
              <span className={`${classes.email} ${classes.obfuscated}`}>
                {subscription.email || t("null")}
              </span>
          </div>
          <div className={classes.row}>
            <label className={classes.label}>{t("last_action")}</label>
            <span className={classes.span}>{DateFormatSubscription(subscription.lastAction) || t("null")}</span>
          </div>

          {canEdit && (
            <div className={classes.actionContainer}>
              <Button
                  title={t("manage")}
                  className={classes.manageBtn}
                  onClick={onEdit}
                />
            </div>
          )}
        </div>
      ) : (
        /* Pending Subscription Content */
        <>
          <div className={classes.subscriptionDetails}>
            <span className={classes.statusLabel}>{t("pending_subscription")}</span>
            
            <img
              src={unLinkedIcon}
              alt={t("unlinked")}
              className={classes.statusIcon}
            />

            <div className={classes.emailDetails}>
              <div className={classes.row}>
                <label className={classes.label}>{t("email")}</label>
                  <span className={`${classes.email} ${classes.obfuscated}`}>
                    {subscription.email || t("null")}
                  </span>
              </div>
            </div>
            <div className={classes.emailDetails}>
              <div className={classes.row}>
                <label className={classes.label}>{t("Offer")}</label>
                <span className={classes.span}>
                    {subscription.offer || t("null")}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.buttonContainer}>
                <Button
                  title={t("resend_password")}
                  className={classes.manageBtn}
                  onClick={onResendPassword}
                  icon= {sendIcon}
                />
          </div>
        </>

      )}

      {/* Tooltip (Info Message) */}
      {showTooltip && (
        <>
          <div
            className={classes.backdrop}
            onClick={() => setShowTooltip(false)}
          />
          <div className={classes.tooltip}>
            <span className={classes.title}>{t("tool_tips") + " :"}</span>
            <span>{t("unlinked_tooltip_message")}</span>
          </div>
        </>
      )}
    </Card>
  );
}

export default SubscriptionCard;
