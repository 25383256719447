const en = {
  translation: {
    signFixedTitle: "Manage your O.room customers, content and access.",
    signTitle: "Welcome to O.room",
    labelFirstConnection:
      "If this is your first connection as an admin, your password must be updated.",
    labelLogin: "Login",
    labelPassword: "Password",
    labelEmail: "Email",
    placeholderEmail: "Enter your email address",
    placeholderPassword: "Enter your password",
    placeholderPhone: "Enter a phone number",
    labelTemporaryPassword: "Temporary password",
    labelNewPassword: "New password",
    labelCurrentPassword: "Current password",
    labelConfirmPassword: "Confirm new password",
    labelNewEmail: "New Email",
    labelCurrentEmail: "Current Email",
    labelConfirmEmail: "Confirm Email",
    spanPasswordConstraints:
      "Your password must contain at least eight characters, one capital letter, one digit and one special character.",
    btnSignin: "Sign in",
    btnSave: "Save",
    btnEdit: "Edit",
    btnCancel: "Cancel",
    confirm: "Confirm",
    forgotPassword: "Forgot password",
    forgotPasswordLabel:
      "If you forgot your password, please enter your email address below to reset it.",
    forgetPasswordNewLabel:
      "Please create a new password below. Your password must contain at least eight characters, one capital letter, one digit and one special character.",
    forgotPasswordInfo:
      "If you forgot your password, please contact our support at suport@vianeos.com",
    forgotPasswordAccount:
      "Forgot password : If you forgot your administrator password, please call your customer service or send an email to support@vianeos.com",
    Hi: "Hi !",
    forgotPasswordInfoResetPassword:
      "You recently requested to reset your password for your XXX platform. Click on the button below to reset it.",
    forgotPasswordInfoIgnoreResetPassword:
      "If you did not request a password reset, please ignore this email or contact your administrator.",
    forgotPasswordInfoDurationResetPassword:
      "This password reset is only valid for the next 30 minutes.",
    thanks: "Thanks.",
    errorLogin: "Your login and/or password are incorrect. Please check again.",
    error_name_required: "Name is required",
    error_email_required: "Email address is required",
    error_invalid_email: "Invalid email address",
    error_inputs_required: "Inputs are required!",
    error_emails_not_matched: "The emails don't match!",
    error_invalid_inputs: "Invalid inputs",
    error_invalid_password: "Invalid password",
    error_passwords_not_matched: "Passwords do not match",
    error_connection_title: "Impossible to connect to the platform.",
    error_connection_message:
      "Our systems are currently under maintenance. Please check with your admin for further details.",
    activeIPTerminals: "Active IP Terminals",
    clientsLicenses: "Clients Licenses",
    lastConnexion: "Last connexion",
    groups: "Groups",
    channels: "Channels",
    moviesAssets: "Movies Assets",
    seriesAssets: "Series Assets",
    radioStations: "Radio Stations",
    rssFeeds: "RSS Feeds",
    customPage: "Custom Page",
    mosaics: "Mosaics",
    accounts: "Accounts",
    dashboard: "Dashboard",
    statistics: "Statistics",
    network: "Network",
    ipTerminals: "IP Terminals",
    terminals: "Terminals",
    zones: "Zones",
    customers: "Customers",
    messages: "Messages",
    contents: "Contents",
    liveTv: "Live TV",
    movies: "Movies",
    series: "Series",
    radios: "Radios",
    applications: "Applications",
    packages: "Packages",
    epg: "EPG",
    portal: "Portal",
    homeSettings: "Home Settings",
    menu: "Menu",
    webApp: "Web App",
    languages: "Languages",
    settings: "Settings",
    globalSettings: "Global Settings",
    config: "Config",
    logout: "Log out",
    footer: "Vianeos 2024 - All rights reserved",
    id: "ID",
    mac: "MAC",
    mac_Address: "MAC address",
    macAddress: "MAC",
    refnumber: "Refnum",
    reference_number: "Subscriber ID",
    ref_number: "Subscriber ID",
    last_action: "Last Login",
    status: "Status",
    resend: "Resend",
    resend_password: "Resend password",
    resend_password_confirmation_Message:
      "You are about to resend password. Do you want to proceed?",
    rows_per_page: "Items per page",
    Android_ID: "Android ID",
    Login_ID: "Login ID",
    ip: "IP",
    room: "Room",
    roomNumber: "Room Number",
    zone: "Zone",
    type: "Type",
    selectAll: "Select all",
    createTerminal: "Create terminal",
    editTerminal: "Create terminal",
    placeholderMac: "XX:XX:XX:XX:XX:XX",
    placeholderIp: "255.255.255.255",
    myAccount: "My account",
    editMyPassword: "Edit my password",
    editMyEmail: "Edit my Email",
    edit: "Edit",
    delete: "Delete",
    ping: "Ping",
    force: "Force",
    lock: "Lock",
    unlock: "Unlock",
    cleanApps: "Clean apps",
    reboot: "Reboot",
    sleepTime: "Sleep time",
    sleepTimeDescription:
      "Allow to configure the duration before the STB goes into standby mode.",
    never: "Never",
    thirtyMin: "30min",
    oneHour: "1h",
    threeHour: "3h",
    sixHour: "6h",
    twelveHour: "12h",
    confirmation_delete: "Do you really want to delete this box ?",
    confirmation_lock: "Do you really want to lock this box ?",
    confirmation_unlock: "Do you really want to unlock this box ?",
    confirmation_cleanApps:
      "Do you really want to clear all data and empty the cache ?",
    confirmation_ignore: "Do you really want to ignore this message ?",
    confirmation_leave_managing:
      "You will lost these information. Do you want to continue ?",
    confirmationDraft: "Do you want to save this message as a draft ?",
    confirmation_delete_background: "Delete this background ?",
    btnMoreInfos: "More infos",
    btnLessInfo: "Less infos",
    displayAll: "Display all",
    display_all: "Display all",
    standby: "Standby",
    n: "n°",
    name: "Name",
    customer: "Customer",
    subzone: "Subzone",
    deleteZone: "Delete zone?",
    deleteSubzone: "Delete subzone?",
    deleteRoom: "Delete room?",
    en: "English",
    fr: "French",
    ar: "Arabic",
    es: "Spanish",
    ch: "Chinese",
    it: "Italian",
    ru: "Russian",
    de: "German",
    ur: "Urdu",
    addIn: "Add in",
    lang: "Language",
    checkIn: "Check in",
    groupWithS: "Group(s)",
    roomWithS: "Room(s)",
    roomsNameWithS: "Room(s) name",
    zoneWithS: "Zone(s)",
    subzoneWithS: "Sub-zone(s)",
    rooms: "Rooms",
    none: "none",
    at: "at",
    login: "Login",
    password: "Password",
    entitlement: "Entitlement",
    entitlementFor: "Entitlement for",
    billing: "Billing",
    createCustomer: "Create customer",
    myPackages: "My packages",
    packagesOfGroup: "Packages of the group ",
    modifyProvGroup: "Modify the provisionning of the group",
    loginWebapp: "Login webapp",
    passwordWebapp: "Password webapp",
    provisoryPasswordWebapp: "Provisory password webapp",
    all: "All",
    clients: "Clients",
    add: "Add",
    searchCustomer: "Search a customer",
    search: "Search",
    group: "Group",
    newMessage: "New message",
    typeOfMessage: "Type of message",
    wording: "Wording",
    loading: "Loading...",
    noTerminals: "No terminals found.",
    noRooms: "No rooms found.",
    noCustomers: "No customers found.",
    noGroups: "No group found.",
    noMessages: "No messages found.",
    noVods: "No VOD found.",
    noPreferredContents: "There is no preferred contents yet.",
    noItemsInThisSection: "There is no items in this section yet.",
    noItemsMatchSearch: "There is no item matching your search",
    noItems: "There is no items yet.",
    noItemsRow: "There's no items in this row yet.",
    noItemsCreate: "You can create an item by clicking on the « + Add »",
    noItemsTopRightCorner: "button in the top-right corner or",
    noItemsCreateAccount:
      "You can create an account by clicking on the '+' button or here",
    noItemsAddMovie:
      "You can add a movie by clicking on the « Add » button or here",
    noItemsAddSeries:
      "You can add a series by clicking on the « Add » button or here",
    noItemsAddChannel:
      "You can add a channel by clicking on the « Add » button or here",
    noItemsAddPackage:
      "You can add a package by clicking on the « Add » button or here",
    message: "Message",
    message_lowerCase: "message",
    type_message: "Message",
    type_alert_info: "Info",
    type_alert_warn: "Warning",
    type_alert_urgent: "Urgent",
    pinned: "Pinned",
    draft: "Draft",
    typeOfMessageDescription:
      "This message is delivered in the mailbox on the Home page and does not have any priority level.\n Usage examples: to address direct messages such as welcome messages, personalized invitations or dedicated offers.\n Max number of caracters : 600.",
    typeOfMessageInfoDescription:
      "This message is automatically displayed as a pop-up bar at the bottom of the screen.\nUsage examples: to share information about a promotion, a special offer or any notification.\nMax number of caracters : 275 without line break.",
    typeOfMessageWarningDescription:
      "This message is automatically displayed as a full pop-up in the middle of the screen with a mandatory countdown.\n Usage examples: when there is an emergency or if you want to make sure that your customers have read your message.\n Max number of caracters : 250.",
    typeOfMessageUrgentDescription:
      "This message is automatically displayed as a full pop-up in the middle of the screen with a mandatory countdown.\n Usage examples: when there is an emergency or if you want to make sure that your customers have read your message.\n Max number of caracters : 250.",
    pinThisMessage: "Pin this message",
    to: "To",
    object: "Object",
    read: "Read",
    unread: "Unread",
    existingMessages: "Existing messages",
    recipients: "Recipients",
    addRecipients: "Add recipients",
    availableCustomers: "Available customers",
    selectedCustomers: "Selected customers",
    availableGroups: "Available groups",
    selected: "Selected",
    editMessage: "Edit message",
    saveAsDraft: "Save as a draft",
    send: "Send",
    yes: "Yes",
    no: "No",
    createAccount: "Create account",
    modifyAccount: "Modify account",
    permissions: "Permissions",
    profileManagement: "Profile management",
    force_standby_refresh: "Force, standby and refresh",
    modify_delete: "Modify and delete",
    move_rooms: "Move rooms",
    read_show_info: "Read and show more info",
    messaging_provisionning: "Messaging and provisionning",
    messaging_provisionning_add:
      "Messaging, provisionning and add client to a group",
    type_message_info: "Types of message: message and info",
    type_warning_urgent: "Types of message: warning and urgent",
    move_contents: "Move contents",
    add_remove_contents: "Add/Remove contents to/from packages",
    editPassword: "Edit password",
    cancel: "Cancel",
    deleteAccount: "Delete this account ?",
    delete_account: "Delete account",
    conf_delete_account: "Are you sure you want to delete {{name}}'s account ?",
    consultation_only: "Consultation only",
    accountNamed: "The account named ",
    deletedSuccessfully: " has been deleted with success",
    manage: "Manage",
    ignore: "Ignore",
    ipChangedMessage: "The IP of a box has changed at ",
    ip_of: "The IP of ",
    ipsChangedMessage: " boxes has changed at ",
    changedIp: "Changed IP",
    vod: "Video on Demand",
    VOD: "VOD",
    movie: "Movie",
    theme: "Theme",
    modify: "Modify",
    newMovie: "New movie",
    createTheme: "Create theme",
    delete_non_empty_cat: "You cannot delete a non-empty category",
    summary: "Summary",
    title: "Title",
    abc: "abc",
    duration: "Duration",
    hour: "Hour",
    min: "Min",
    sec: "Sec",
    upload_image_min_resolution:
      "Please upload your background with a minimum resolution of 1280x720",
    rating: "Rating",
    cast_crew: "Cast & Crew",
    themes: "Themes",
    only_4_themes: "You could choose only 4 themes.",
    subtitles: "Subtitles",
    format: "Format",
    price: "Price",
    currency: "Currency",
    urlMovie: "URL Movie",
    urlTrailer: "URL Trailer",
    addMember: "Add a member",
    actors: "Actors",
    actor: "Actor",
    directors: "Directors",
    director: "Director",
    no_result_found: "No result found",
    add_in_database: "Add in the database",
    https: "https://...",
    firstName: "First name",
    lastName: "Last name",
    category: "Category",
    cat: "category",
    rank: "Rank",
    upload_icon_theme:
      "Please upload your icon with a maximum resolution of 200x200",
    choose_icon: "You can choose your icon, it will appear on your Home",
    existingThemes: "Existing themes",
    number: "Number",
    add_movies_in: "Add movies in",
    selectTheme: "Select a theme",
    availableMovies: "Available movies",
    selectedMovies: "Selected movies",
    editTheme: "Edit theme",
    warning: "Warning",
    contentWithS: "content(s)",
    createNewSeries: "Create new series",
    information: "Information",
    seasons_episodes: "Seasons & episodes",
    season: "Season",
    episodes: "Episodes",
    noEpisodes: "No Episodes found.",
    createNewEpisode: "Create episode",
    urlEpisode: "URL Episode",
    free: "Free",
    noChannels: "No channels found.",
    newChannel: "New channel",
    link_with_epg: "Link with EPG data",
    link_channel_with_epg: "Link {{channel}} with EPG data",
    edit_link_with_epg: "Edit EPG link",
    link_channel_epg: "Link your channel with EPG data",
    logo_format: "Please, use a logo in PNG format and pale colouring.",
    metadata: "Metadata",
    url: "URL",
    metadata_span:
      "If your do not have the corresponding metadata in the EPG database, you can define a generic baseline for the channel that will appear in the EPG.",
    linked: "Linked",
    logo: "Logo",
    search_channel: "Search channel",
    noResultFound: "No result found",
    change_channels_ranking: "Change channels ranking",
    change_item_ranking: "Change item ranking",
    remove_channel_gaps: "Remove channel gaps",
    delete_channel: "Delete channel ?",
    unselect_all: "Unselect all",
    different_channel_gaps: "You have different channel gaps.",
    which_one_remove: "Which one do you want to remove?",
    remove: "Remove",
    between_rank: "Between rank",
    and: "and",
    position_taken: "Position already taken by: ",
    switch_channel_number: "Do you really want to switch channel number?",
    switch: "Swicth",
    created_the: "Created the",
    modified: "Modified",
    deletePackage: "Delete package ?",
    createPackage: "Create package",
    hindi: "Hindi",
    persian: "Persian",
    english: "English",
    french: "French",
    arabic: "Arabic",
    select_theme_rank_taken:
      "If you select a rank which is already taken, themes will swap",
    selectMedia: "Select a media",
    channel: "Channel",
    selectedItems: "Selected items",
    availableItems: "Available items",
    add_media_to: "Add media to ",
    subscriptions: "Subscriptions",
    active_devices: "Active devices",
    backOffice: "Back office",
    content: "Content",
    ott_basic: "OTT Basic",
    ott_advance: "OTT Advance",
    ott_premium: "OTT Premium",
    users: "Users",
    back_office_users: "Back office users",
    back_office: "Back office",
    star: "Star",
    confirmPassword: "Confirm password",
    check: "Check",
    add_modify_delete: "Add, modify and delete",
    edit_menu: "Edit menu",
    edit_home_settings: "Edit home settings",
    devices: "Devices",
    email: "Email",
    create_back_office_user: "Create a back-office user",
    delete_this_account: "Delete this account",
    yes_reset: "Yes, reset",
    no_cancel: "No, cancel",
    resetPassword: "Reset password",
    reset_password_description: "Are you trying to reset {{name}}'s password?",
    toast_update_content_success: "The changes have been succesfully saved.",
    toast_reset_message_success:
      "{{name}}'s password has been successfully reset and sent to his email address.",
    toast_create_category_success:
      "The category named {{name}} has been created.",
    toast_edit_category_success:
      "The category {{name}} has been successfuly saved.",
    toast_delete_category_success: "The category has been successfully deleted",
    toast_reset_message_failed:
      "{{name}}'s password reset failed! Please try again later.",
    toast_delete_account_message_success:
      "{{name}}'s account has been successfully deleted.",
    toast_delete_account_message_failed:
      "{{name}}'s account deletion failed! Please try again later.",
    toast_resend_password_success:
      "The password of the {{mac}} device has been successfully sent.",
    toast_unlink_subscription:
      "The {{mac}} device has been successfully delink.",
    toast_delete_faq_section_success:
      "The section has been successfully deleted",
    toast_delete_faq_subsection_success:
      "The subsection has been successfully deleted",
    toast_delete_emirate_success:
      "The Emirate {{emirate}} and its towns ({{length}}) has been successfully deleted.",
    toast_delete_town_success:
      "The town {{town}} has been successfully deleted.",
    unlink_confirmation_Message:
      "You are about to unlink the subscription from this device. Do you want to proceed?",
    logout_confirmation_message: "Are you sure you want to logout?",
    delete_type_conf_message: "You are about to detele the {{type}}:",
    delete_conf_message: "You are about to detele:",
    delete_type: "Delete {{type}}?",
    every_nested_town: "and every nested towns ({{length}})",
    disappear_from_frontend:
      "It will disappear from all frontend applications.",
    unlink_this_device: "Unlink this device",
    a_to_z: "A to Z",
    z_to_a: "Z to A",
    least_subscriptions: "Least subscriptions",
    most_subscriptions: "Most subscriptions",
    least: "Least",
    most: "Most",
    searchChannel: "Search a channel",
    searchPackage: "Search a package",
    searchMovie: "Search a movie",
    searchSerie: "Search a serie",
    searchSubscription: "Search a subscription",
    close: "Close",
    offer: "Offer",
    view_more: "View more",
    added_the: "Added the",
    company: "Company",
    subscription: "Subscription",
    offers: "Offers",
    options: "Options",
    allOptions: "All options",
    basic_offers: "Basic offers",
    advance_offers: "Advance offers",
    premium_offers: "Premium offers",
    pending_sub: "Pending subscriptions",
    pending_subscription: "Pending subscription",
    in_pending: "In pending",
    linked_sub: "Linked subscriptions",
    device: "Device",
    add_ons: "Add-ons",
    ppv: "PPV",
    history: "History",
    view_more_history: "View more history",
    unlink_sub: "Unlink subscription",
    allHistory: "All history",
    addons: "Add-ons",
    activeDevices: "Devices",
    totalSubscriptions: "Subscriptions",
    customerName: "Company",
    ipAddress: "IP",
    deviceId: "MAC",
    null: "Null",
    no_history: "No history",
    unlink: "Unlink",
    sww: "Something went wrong!",
    unlinked_tooltip_message:
      "This subscription is not used and not linked to any device. To link it, use the credentials on a device that is currently not linked to any subscription.",
    tool_tips: "Tool tips",
    history_subscription:
      "Subscription to the {{plan}} {{type}} is {{action}} on {{dateTime}}",
    rows: "Rows",
    row: "row",
    pages: "Pages",
    type_home: "Home",
    type_media_vod: "VOD",
    role: "Role",
    addOn: "Add-on",
    edit_page_rows_ranking: "Edit {{pageName}} page rows ranking",
    verticals: "Verticals",
    createVertical: "Create vertical",
    modifyVertical: "Modify {{title}}",
    add_contents: "Add contents",
    notFound: "Not found!",
    page_not_found: "Page not found",
    page_not_found_desccription:
      "Oops! The page you are looking for does not exist.",
    back: "Back",
    add_vods_to_type: "Add VODs to {{type}}",
    add_contents_to_type: "Add contents to {{type}}",
    no_contents_to_add: "There is no contents to add",
    preferences: "Preferences",
    ageFilter: "Age filter",
    vertical: "Vertical",
    vertical_lowerCase: "vertical",
    language: "Language",
    location: "Location",
    noContent: "No content",
    allCustomers: "All customers",
    search_for_customer: "Search for a customer",
    search_for_recipient: "Search for a recipient",
    create: "Create {{type}}",
    modifyTitle: "Modify {{title}}",
    newContents: "New contents",
    link_not_assigned_contents: "Link not assigned contents",
    edit_attachments: "Edit attachments",
    linkContents: "Link contents",
    edit_category: "Edit {{name}}",
    createCategory: "Create category",
    subscriberName: "Name",
    unlink_epg: "Unlink EPG",
    default: "Default",
    base: "Offer",
    business_type: "Business type",
    preferredContents: "Preferred contents",
    preferredContent: "preferred content",
    storeLocations: "Store locations",
    store_location: "Store location",
    storeLocation: "store location",
    emirate: "Emirate",
    town: "Town",
    emirate_town: "Emirate - Town",
    enable_two_factor_auth: "Enable two factor authentication",
    customerAccountId: "Account Id",
    twoFA: "Two-Factor Authentication",
    verificationCode: "Verification code",
    submit: "Submit",
    scanQR: "Scan QR",
    enterVerificationCode:
      "Enter the verification code received from the authenticator app",
    searchContent: "Search a content",
    subscribers: "Subscribers",
    home_settings: "Home settings",
    custom_pages: "Custom pages",
    custom_page: "custom page",
    background: "Background",
    welcome_message: "Welcome message",
    modify_custom_page: "Modify your custom page",
    create_custom_page: "Create your custom page",
    power_point_template: "Powerpoint template",
    power_point_description_phrase:
      "To use PowerPoint in order to create a custom page :",
    power_point_description_1:
      "Please download one of the templates available below",
    power_point_description_2: "Open that template in PowerPoint,",
    power_point_description_3:
      " Insert your picture behind the black area and type your text on top of it,",
    power_point_description_4: "Save that file in .jpg or .png,",
    power_point_description_5:
      "Upload that picture on O.room by clicking on the + icon available in the “Import your image” section.",
    power_point_description_phrase_2:
      "Please bear in mind that one picture equals a custom page.",
    import_your_image: "Import your image:",
    add_image_type: "Add .jpg or .png or .mp4 file",
    drag_drop_menu: "Drag and drop your menu to organize your Home",
    drag_n_drop_content: "Drag and drop your content items to organize.",
    local_attractions: "Local attractions",
    catchup: "Catch-up",
    add_logo_png_format: "Add your logo (png format only)",
    upload_background_min_resolution:
      "Please upload your background with a minimum resolution of 1280x720. Drag and drop your slides to organize your page",
    signature: "Signature",
    disable_wlc_msg: "Disable welcome message",
    help: "Help",
    contact_us: "Contact us",
    faq: "FAQ",
    terms_conditions: "Terms & conditions",
    fill_in_contact:
      "Please fill in your contact information for your customers.",
    fill_in_terms: "Please fill in the terms and conditions of your product.",
    support_mail: "Support Mail",
    phone_number: "Phone number",
    no_selected_contents: "No content selected!",
    select_cat: "Select a category!",
    placeholder_enter_details_contact_us:
      "Enter more details here on how to get in touch with the support team.",
    add_new_section: "Add new section",
    section: "Section",
    subsection_key: "Subsection {{key}}",
    enter_section_name: "Enter the name of your section here",
    add_new_subsection: "Add new subsection",
    map: "Map",
    countries: "{{number}} countries",
    legend_customers: "{{from}} to {{to}} customers",
    more_than_1000: "More than 1000 customers",
    search_country: "Search a country",
    bank: "Bank ({{number}})",
    hospital: "Hospital ({{number}})",
    restaurant: "Restaurant ({{number}})",
    hotel: "Hotel ({{number}})",
    shop: "Shop ({{number}})",
    here: "here",
    choose_cat_icon: "You can choose your icon, it will appear on your Home",
    undo: "Undo",
    delete_package: "Delete package?",
    delete_FAQ_section_description:
      "You are about to delete the section: {{section_title}}",
    delete_FAQ_subsection_description:
      "You are about to delete the section: {{subsection_title}}",
    delete_content_type: "Delete content type",
    delete_preferred_content_description:
      "You are about to delete : {{content_title}}",
    confirm_VODs_addition_to: "Confirm VODs Addition to {{type}}",
    confirm_content_selection:
      "Are you sure you want to proceed with adding {{selection_number}} VODs to {{type}}?",
    create_new_content_type: "Create new content type",
    edit_content_type: "Edit content type",
    auto_rank_last_if_no_number:
      "If no number is entered, the item will automatically be ranked last.",
    item_removal_frontend_impact:
      "It will disappear from all frontend applications.",
    visuals: "Visuals",
    poster: "Poster",
    landscape: "Landscape",
    preview: "Preview",
    user_activity: "User activity",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    total_hours_consumed: "Total hours consumed",
    actions: "Actions",
    emirates: "Emirates",
    create_new_location: "Create new location",
    select_emirate: "Select an emirate",
    add_town: "Add Town",
    town_name: "Town name",
    toast_create_preferred_content_success:
      "The new content type has been created.",
    toast_update_preferred_content_success:
      "The content type has been succesfully updated.",
    toast_delete_preferred_content_success:
      "The content type has been successfully deleted.",
    edit_emirate: "Edit emirate",
    edit_town: "Edit town",
    content_refreshed_successfully: "Contents refreshed successfully",
    higher_rank: 'By rank'
  },
};

export default en;
